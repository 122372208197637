<template>
  <div>
    <div>
      <p class="font-bold">Hva er bruksrett?</p>
      <p>En bruksrett er en spesiell tillatelse som gir et foretak lov til å bruke skogeiendommen. Det betyr ikke
        at de eier skogeiendommen, men at de blant annet kan signere kontrakter på vegne av skogeier.</p>
    </div>
    <div class="mt-2">
      <p class="font-bold">Hvorfor må bruksrett opprettes?</p>
      <p>Kontrakter som inngås med Glommen Mjøsen Skog må skje gjennom et foretak. Hvis skogeier ikke har et
        enkeltpersonsforetak, må det opprettes og gis en bruksrett til et foretak som kan inngå kontrakter på
        vegne av skogeier.</p>
    </div>
    <div class="my-2">
      <p class="font-bold">Hvem skal signere på bruksretten?</p>
      <p>Både eier(e) av eiendommen og eier(e) av foretaket som får bruksrett må signere på bruksretten.</p>
    </div>
  </div>
</template>
